import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

export const query = graphql`{
  fileName: file(relativePath: {eq: "over-ons-yvonne.jpeg"}) {
    childImageSharp {
      gatsbyImageData(width: 675, height: 900, layout: CONSTRAINED)
    }
  }
}
`

const AboutMe = ({ data }) => (
  <Layout pageInfo={{ pageName: "over-mij", pageTitle: "Over mij" }}>
    <Seo title="Over mij"/>

    <Container>
      <section className="general-page" id="about-us">

        <Container>
          <Row>
            <Col md={{ span: 4, offset: 1 }}>
              <GatsbyImage image={data.fileName.childImageSharp.gatsbyImageData} alt="" />
            </Col>
            <Col md={{ span: 6 }}>
              <div class="block">
                <p><h4>Hallo allemaal!</h4></p>

                <p>
                  Mijn naam is Yvonne en ik ben de eigenaresse van Wondere Wereld van Tekeningen.
                  Daarnaast ben ik eigenaresse van Alles Kids Kindercoaching, waar ik zowel kinderen als hun ouders
                  en/of verzorgers coach. Ik ben getrouwd met Erik en samen hebben wij twee geweldige kinderen gekregen,
                  Dex en Fenne. Erik heeft mij geholpen met het opzetten van onder meer Wondere Wereld van Tekeningen en
                  doet dat nu ook nog steeds.
                </p>

                <p>
                  Mijn kinderen, vooral mijn dochter, tekenen vanaf het moment dat ze strepen konden zetten al heel
                  veel. Ik wist wel dat je uit tekeningen veel informatie kunt halen en ben hier altijd in
                  geïnteresseerd geweest, maar ik had eigenlijk geen idee wat allemaal precies. Op een gegeven moment
                  kwam ik een training tegen, geregistreerd bij de Stichting Kwaliteitsregister Jeugd, om
                  kindertekeningen te leren lezen en begrijpen. Na even twijfelen heb ik al snel de knoop doorgehakt:
                  dit ga ik doen!
                </p>
                <p>
                  De training was geweldig, wat een openbaring! Nooit had ik kunnen bedenken dat je zoveel informatie
                  kunt halen uit de meest simpele tekeningen, zelfs uit (in de ogen van volwassenen) wat gekras. In de
                  training heb ik ook zelf tekeningen (zonder concrete opdracht) gemaakt welke ik later in de training
                  moest analyseren, maar dat wist ik op het moment van tekenen nog niet. Eigenlijk heel bijzonder wat je dan
                  van jezelf terug ziet, hoe je onbewust toch dingen op papier verwerkt. Maar ook heel leuk om dit zelf
                  te ervaren! Op dat moment wist ik al dat ik hier meer mee wilde doen.
                </p>

                <p>
                  Al snel ontstond het idee voor Wondere Wereld van Tekeningen. Hoe leuk zou het zijn als ik kinderen en
                  volwassenen meer zou kunnen vertellen over wat er in hun tekening te zien is!? En ik ouders of
                  professionals kan helpen meer (en sneller) duidelijkheid te krijgen over wat er bij een kind speelt?
                  Dat is wel iets waar ik me graag voor in wil zetten.
                </p>

              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Container>
  </Layout>
)

export default AboutMe
